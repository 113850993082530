.plays-container {
  background-color: #fff;
  padding: 2% 2.5%;

  .plays-table {
    .plays-table-row {
      &:hover {
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
          0 1px 5px rgba(0, 0, 0, 0.22);
      }
    }
  }
}
