.resetPassword-container {
  .resetPassword-heading {
    color: #393f5b;
    font-size: 30px;
    font-weight: "bold";
    font-family: "NunitoSans-Bold";
  }
  .textInput {
    font-family: "NunitoSans-Regular";
    font-size: 18px;
    color: "#757575";
  }
  .textInputLable {
    font-family: "NunitoSans-Regular";
    font-size: 18px;
    color: "#757575";
  }
  .sendCode-Btn {
    background-color: #343f84;
    text-transform: none;
    height: 45px;
    width: 140px;
    padding: 0px 20px;
    font-size: 15px;
    font-family: "NunitoSans-Bold";
    border-radius: 8px;
  }
  .backToSignIn-Btn {
    color: blue;
    text-transform: none;
    padding: 0;
    color: #343f84;
    font-weight: bold;
    &:hover {
      text-decoration: underline;
    }
    font-family: "NunitoSans-Black";
  }
}
