body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f2f2f2 !important;
  overflow-y: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "NunitoSans-Black";
  src: local("NunitoSans-Black"),
    url("./assets/fonts/NunitoSans-Black.ttf") format("truetype");
}

@font-face {
  font-family: "NunitoSans-Bold";
  src: local("NunitoSans-Bold"),
    url("./assets/fonts/NunitoSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "NunitoSans-Regular";
  src: local("NunitoSans-Regular"),
    url("./assets/fonts/NunitoSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "NunitoSans-ExtraBold";
  src: local("NunitoSans-ExtraBold"),
    url("./assets/fonts/NunitoSans-ExtraBold.ttf") format("truetype");
}
