.carousel {
  overflow: hidden;
  flex: 1;
}


.carousel:after {
  content: "";
  position: absolute;
  z-index: 1;
  width: 100px;
  top: 0;
  height: 100%;

  right: 0;
  background: linear-gradient(to left, #F4F4F4 25px, rgba(0, 0, 0, 0));
}


.slick-slide>div {
  margin: 0 25px;
  width: 200px;
  height: 150px
}

.slick-list {
  margin: 0 -25px;
}